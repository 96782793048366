import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Menu, Icon } from "antd";

import { useUserPermissions } from "auth/UserHooks";
import { useFeatures } from "hooks/FeatureHooks";

const NavMenu = props => {
  const user = useUserPermissions();
  const features = useFeatures();

  // TODO: determine absolute order to follow for menu items
  const menuItems = [
    {
      label: "Home",
      url: "/",
      icon: "home"
    },
    {
      label: "Employees",
      url: "/employees",
      icon: "contacts",
      hidden: !user.canReadEmployees
    },

    {
      label: "Projects",
      url: "/projects",
      icon: "project",
      hidden: !user.canReadProjects
    },

    {
      label: "Capabilities/Specializations",
      url: "/capabilities",
      icon: "wallet",
      hidden: !user.canReadCapabilities
    },

    {
      label: "Entities",
      url: "/entities",
      icon: "select",
      hidden: !user.canReadEntities
    },

    {
      label: "Industries",
      url: "/industries",
      icon: "shop",
      hidden: !user.canReadIndustries  
    },

    {
      label: "Skills",
      url: "/skills",
      icon: "compass",
      hidden: !user.canReadSkills
    },
    {
      label: "Role Types",
      url: "/role-types",
      icon: "team",
      hidden: !user.canReadRoleTypes
    },

    {
      label: "Certifications",
      url: "/certifications",
      icon: "solution",
      hidden: !user.canReadCertifications
    },
    //
    // {
    //   label: 'Badges',
    //   url: '/badges',
    //   icon: 'safety-certificate',
    //   hidden: !user.canViewBadges
    // },
    {
      label: "Prompts",
      url: "/prompts",
      icon: "question-circle",
      hidden: !user.canReadPromptResponses
    },
    {
      label: "Key Metrics",
      url: "/key_metrics",
      icon: "key",
      hidden:
        (features && features.isEnabled("DISABLE_KEY_METRICS")) ||
        (!user.canReadKeyMetrics &&
          !user.canReadPracticeKeyMetrics &&
          !user.canReadCoacheeKeyMetrics)
    },
    {
      label: "Reports",
      url: "/reports",
      icon: "bar-chart",
      hidden: !user.canReadReports
    }
  ];

  const adminMenuItems = [
    {
      label: "Projector",
      url: "/admin/projector",
      icon: "link",
      hidden: !user.canViewProjectorSkills
    },
    {
      label: "Tags",
      url: "/admin/tags",
      icon: "tag",
      hidden: !user.canViewAdminTags
    },
    {
      label: "Business Units",
      url: "/admin/employee_business_units",
      icon: "bank",
      hidden: !user.canViewEmployeeBusinessUnit
    },
    {
      label: "Employee Practice",
      url: "/admin/employeepractice",
      icon: "contacts",
      hidden: !user.canViewEmployeePractice
    },
    {
      label: "Employee Region",
      url: "/admin/employeeregion",
      icon: "global",
      hidden: !user.canViewEmployeeRegion
    },
    {
      label: "Employee Location",
      url: "/admin/employeeLocation",
      icon: "pushpin",
      hidden: !user.canViewEmployeeLocation
    },
    {
      label: "Role Type Family",
      url: "/admin/roletypefamily",
      icon: "team",
      hidden: !user.canViewAdminRoleTypeFamily
    },
    {
      label: "Features",
      url: "/admin/features",
      icon: "flag",
      hidden: !user.canManageSessions
    },
    {
      label: "Sessions",
      url: "/admin/sessions",
      icon: "laptop",
      hidden: !user.canManageSessions
    },
    {
      label: "Log",
      url: "/admin/log",
      icon: "warning",
      hidden: !user.canViewAdminLog
    },
    {
      label: "User Access",
      url: "/admin/user_access",
      icon: "control",
      hidden: !user.canManageRoles
    }
  ];

  let newSelectedKey = "/";

  if (props.location.pathname !== "/") {
    const links = [...menuItems, ...adminMenuItems].map(m => m.url).slice(1); // Skip home page
    const selectedItem = links.find(l => props.location.pathname.startsWith(l));
    if (selectedItem != null) {
      newSelectedKey = selectedItem;
    }
  }

  return (
    <Menu
      style={{ height: "100%" }}
      onClick={e => props.history.push(e.key)}
      mode="inline"
      selectedKeys={[newSelectedKey]}
    >
      {menuItems
        .filter(i => !i.hidden)
        .map(i => {
          return (
            <Menu.Item key={i.url} disabled={i.disabled}>
              {i.icon && <Icon type={i.icon} width={16} height={16} />}
              <span>{i.label}</span>
            </Menu.Item>
          );
        })}
      {adminMenuItems.filter(i => !i.hidden).length > 0 && (
        <Menu.ItemGroup key="admin" title="Admin">
          {adminMenuItems
            .filter(i => !i.hidden)
            .map(i => (
              <Menu.Item key={i.url} disabled={i.disabled}>
                {i.icon && <Icon type={i.icon} width={16} height={16} />}
                <span>{i.label}</span>
              </Menu.Item>
            ))}
        </Menu.ItemGroup>
      )}
    </Menu>
  );
};

NavMenu.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default withRouter(NavMenu);
