import { Capability, LandingPageCapabilityAPI } from "./Capability";
import { Industry, LandingPageIndustryAPI } from "./Industry";
import { CertificationSummary } from "./CertificationSummary";
import { EmployeePractice } from "./EmployeePractice";
import { RoleTypeFamily } from "./RoleTypeFamily";
import { RoleTypeSummary } from "./RoleTypeSummary";
import { LandingPageSkill, LandingPageSkillAPI } from "./Skill";
import { SkillCategory } from "./SkillCategory";

export interface ProjectorSkill {
  id: number;
  name: string;
  groupName: string;
  entity: string;
  tableId: number;
  isDeleted: boolean;
  contains?: ProjectorSkill[];
}

export interface ProjectorUdf {
  id: number;
  name: string;
  isDeleted: boolean;
}

export interface RawResponse {
  projectorSkills: ProjectorSkill[];
  capabilitiesAPI: LandingPageCapabilityAPI[];
  capabilities: Capability[];
  industriesAPI: LandingPageIndustryAPI[];
  industries: Industry[];
  roleTypes: RoleTypeSummary[];
  skillsAPI: LandingPageSkillAPI[];
  skills: LandingPageSkill[];
  certifications: CertificationSummary[];
  skillCategories: SkillCategory[];
  roleTypeFamily: RoleTypeFamily[];
  employeePractices: EmployeePractice[];
}

export class ProjectorConstants {
  static ENTITY_CAPABILITY: string = "Capability/Specialization";
  static ENTITY_INDUSTRY_MAJOR: string = "Industry";
  static ENTITY_INDUSTRY_MINOR: string = "Industry";
  static ENTITY_SKILL: string = "Skill";
  static ENTITY_CERTIFICATION: string = "Certification";
  static ENTITY_ROLE_TYPE: string = "Role Type";

  static entities = [
    { key: this.ENTITY_CERTIFICATION, text: this.ENTITY_CERTIFICATION },
    { key: this.ENTITY_CAPABILITY, text: "Minor Capability/Specialization" },
    { key: this.ENTITY_INDUSTRY_MAJOR, text: "Major Industry" },
    { key: this.ENTITY_INDUSTRY_MINOR, text: "Minor Industry" },
    { key: this.ENTITY_ROLE_TYPE, text: this.ENTITY_ROLE_TYPE },
    { key: this.ENTITY_SKILL, text: this.ENTITY_SKILL }
  ];
}
