import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Select,
  Switch,
  TreeSelect
} from "antd";
import { chain, debounce, intersection, toInteger } from "lodash";
import SelectStack from "./SelectStack";
import {
  experienceLevels,
  prExperienceLevels
} from "components/constants/Constants";
import { EMPLOYEE_ACTIVE_DELIMITER } from "config";
import TreeSelectRecursive from "components/skills/skill/TreeSelectRecursive";
import moment from "moment";
import enumToDisplayText from "utils/enumToDisplayText";
import sorters from "utils/sorters";

export const filterAdvancedEmployees = (props, changedValues, values) => {
  const { employees, onSearch, setSettings } = props;

  const uppercaseTerm = values.searchTerm
    ? values.searchTerm.toUpperCase()
    : "";
  const practices = values.selectedPractices;
  const businessUnits = values.selectedBusinessUnits;
  const positions = values.selectedPositions;
  const levels = values.selectedLevels;
  const regions = values.selectedRegions;
  const locations = values.selectedLocations;
  const capabilities = values.selectedCapabilities;
  const minorOrMajor = values.selectedMinorOrMajor;
  const skills = values.selectedSkills;
  const skillExpLevels = values.selectedSkillExpLevels;
  const skillSearchType = values.selectedSkillSearchType;
  const certifications = values.selectedCertifications;
  const roleTypes = values.selectedRoleTypes;
  const prExpLevels = values.selectedPRExpLevels;
  const selectedPrompts = values.selectedPrompts;
  const clients = values.selectedClients;
  const projects = values.selectedProjects;
  const hours = values.selectedHours;
  const endDate = values.selectedEndDate;
  const entities = values.selectedEntities;
  const prompts = selectedPrompts
    ? convertPromptStructure(selectedPrompts)
    : [];
  let includeInactiveCerts = false;
  if (values.selectedActive) {
    includeInactiveCerts = values.selectedActive.length > 0;
  }

  setSettings({
    search: values.searchTerm,
    practice: practices,
    businessUnit: businessUnits,
    position: positions,
    level: levels,
    region: regions,
    office: locations,
    entity: entities,
    capability: capabilities,
    minorOrMajor: minorOrMajor,
    skills: skills,
    skillExpLevel: skillExpLevels,
    skillSearchType: skillSearchType, // false = OR, true = AND
    certification: certifications,
    certActive: values.selectedActive,
    roleType: roleTypes,
    prExpLevel: prExpLevels,
    prompt: selectedPrompts,
    client: clients,
    project: projects,
    hours: hours,
    endDate: endDate
  });

  const filteredEmployees = employees.filter(e => {
    const minorCapNames = chain(e.minor.split(EMPLOYEE_ACTIVE_DELIMITER))
      .map(minor => minor.trim())
      .filter(minor => minor !== "")
      .uniq()
      .sort()
      .value();
    const clientNames = chain(e.clients.split(EMPLOYEE_ACTIVE_DELIMITER))
      .map(client => client.trim())
      .filter(client => client !== "")
      .uniq()
      .sort()
      .value();
    const projectNames = chain(e.projects.split(EMPLOYEE_ACTIVE_DELIMITER))
      .map(project => project.trim())
      .filter(project => project !== "")
      .uniq()
      .sort()
      .value();

    let isNameValid =
      !uppercaseTerm ||
      !uppercaseTerm.length ||
      e.fullName.toUpperCase().indexOf(uppercaseTerm) > -1 ||
      e.staffingPreferences.toUpperCase().indexOf(uppercaseTerm) > -1;
    let isPracticeValid =
      !practices || !practices.length || practices.indexOf(e.practice) > -1;
    let isBusinessUnitValid =
      !businessUnits ||
      !businessUnits.length ||
      businessUnits.indexOf(e.businessUnit) > -1;
    let isPositionValid =
      !positions || !positions.length || positions.indexOf(e.title) > -1;
    let isLevelValid =
      !levels || !levels.length || levels.indexOf(e.level) > -1;
    let isRegionValid =
      !regions || !regions.length || regions.indexOf(e.region) > -1;
    let isLocationValid =
      !locations || !locations.length || locations.indexOf(e.location) > -1;
    let isEntityValid =
      !entities || !entities.length || entities.indexOf(e.entity) > -1;
    let areCapabilitiesValid;
    if (
      !minorOrMajor ||
      minorOrMajor.length === 0 ||
      minorOrMajor.length === 2
    ) {
      areCapabilitiesValid =
        !capabilities ||
        !capabilities.length ||
        intersection([...minorCapNames, e.major], capabilities).length > 0;
    } else if (minorOrMajor[0] === "Major") {
      areCapabilitiesValid =
        !capabilities ||
        !capabilities.length ||
        capabilities.indexOf(e.major) > -1;
    } else {
      areCapabilitiesValid =
        !capabilities ||
        !capabilities.length ||
        intersection(minorCapNames, capabilities).length > 0;
    }
    let areSkillsValid = true;
    let arePRsValid = true;
    let areCertificationsValid = true;
    let arePromptsValid = true;

    if (e.advancedSearch) {
      if (
        skills &&
        skills.length > 0 &&
        (skillExpLevels && skillExpLevels.length > 0)
      ) {
        if (skillSearchType) {
          areSkillsValid =
            e.advancedSearch.employeeSkillBriefs.filter(
              esb =>
                skills.indexOf(esb.id) > -1 &&
                skillExpLevels.indexOf(esb.experienceLevel) > -1
            ).length === skills.length;
        } else {
          areSkillsValid =
            e.advancedSearch.employeeSkillBriefs.filter(
              esb =>
                skills.indexOf(esb.id) > -1 &&
                skillExpLevels.indexOf(esb.experienceLevel) > -1
            ).length > 0;
        }
      } else {
        if (skillSearchType) {
          areSkillsValid =
            !skills ||
            !skills.length ||
            intersection(
              e.advancedSearch.employeeSkillBriefs.map(esb => esb.id),
              skills
            ).length === skills.length;
        } else {
          areSkillsValid =
            !skills ||
            !skills.length ||
            intersection(
              e.advancedSearch.employeeSkillBriefs.map(esb => esb.id),
              skills
            ).length > 0;
        }
      }
      if (
        roleTypes &&
        roleTypes.length > 0 &&
        (prExpLevels && prExpLevels.length > 0)
      ) {
        arePRsValid =
          e.advancedSearch.employeeRoleTypeBriefs.filter(
            eprb =>
              roleTypes.indexOf(eprb.id) > -1 &&
              prExpLevels.indexOf(eprb.experienceLevel) > -1
          ).length > 0;
      } else {
        arePRsValid =
          !roleTypes ||
          !roleTypes.length ||
          intersection(
            e.advancedSearch.employeeRoleTypeBriefs.map(eprb => eprb.id),
            roleTypes
          ).length > 0;
      }
      areCertificationsValid =
        !certifications ||
        !certifications.length ||
        intersection(
          e.advancedSearch.employeeCertificationBriefs
            .filter(ecb => ecb.active || includeInactiveCerts)
            .map(ecb => ecb.id),
          certifications
        ).length > 0;

      arePromptsValid =
        !prompts ||
        !prompts.length ||
        prompts.filter(
          prompt =>
            intersection(
              prompt.optionIds,
              e.advancedSearch.employeePromptBriefs
                .filter(epb => epb.promptId === prompt.promptId)
                .map(epb => epb.responseId)
            ).length > 0
        ).length === prompts.length;
    }
    let isClientValid =
      !clients ||
      !clients.length ||
      intersection(clients, clientNames).length > 0;
    let isProjectValid =
      !projects ||
      !projects.length ||
      intersection(projects, projectNames).length > 0;
    let isDateValid = !endDate || moment(e.endDate).isSameOrBefore(endDate);
    let areHoursValid = !hours || 40 - e.hours >= hours;

    return (
      isNameValid &&
      isPracticeValid &&
      isBusinessUnitValid &&
      isPositionValid &&
      isLevelValid &&
      isRegionValid &&
      isLocationValid &&
      areCapabilitiesValid &&
      areSkillsValid &&
      arePRsValid &&
      areCertificationsValid &&
      arePromptsValid &&
      isClientValid &&
      isProjectValid &&
      isDateValid &&
      areHoursValid &&
      isEntityValid
    );
  });
  onSearch(filteredEmployees);
};

function convertPromptStructure(selectedPrompts) {
  let outPrompts = [];
  for (let selectedPrompt of selectedPrompts) {
    let listPrompt = selectedPrompt.split(",", 2);
    const index = outPrompts.findIndex(
      element => element.promptId === toInteger(listPrompt[0])
    );
    if (index > -1) {
      outPrompts[index] = {
        ...outPrompts[index],
        optionIds: [...outPrompts[index].optionIds, toInteger(listPrompt[1])]
      };
    } else {
      outPrompts.push({
        promptId: toInteger(listPrompt[0]),
        optionIds: [toInteger(listPrompt[1])]
      });
    }
  }

  return outPrompts;
}

const EmployeeAdvancedSearchForm = props => {
  const {
    employees = [],
    onSearch,
    onSubmit,
    advancedSearchFilteredEmployees,
    extraInfoHidden,
    searchRef,
    disabled,
    settings,
    form,
    roleTypes,
    skills,
    certifications,
    certifyingOrgNames,
    prompts
  } = props;

  useEffect(() => {
    if (extraInfoHidden) {
      form.resetFields([
        "selectedClients",
        "selectedProjects",
        "selectedHours",
        "selectedEndDate"
      ]);
      onSearch(employees);
    }
  }, [extraInfoHidden]);

  const practices = [
    ...new Set(
      employees
        .sort((a, b) => sorters.string(a.practice, b.practice))
        .map(e => e.practice)
    )
  ];
  const businessUnits = [
    ...new Set(
      employees
        .sort((a, b) => sorters.string(a.businessUnit, b.businessUnit))
        .map(e => e.businessUnit)
    )
  ];
  const positions = [
    ...new Set(
      employees
        .sort((a, b) => sorters.string(a.title, b.title))
        .map(e => e.title)
    )
  ];
  const levels = [
    ...new Set(
      employees
        .sort((a, b) => sorters.number(a.level, b.level))
        .map(e => e.level)
    )
  ];
  const regions = [
    ...new Set(
      employees
        .sort((a, b) => sorters.string(a.region, b.region))
        .map(e => e.region)
    )
  ];
  const locations = [
    ...new Set(
      employees
        .sort((a, b) => sorters.string(a.location, b.location))
        .map(e => e.location)
    )
  ];
  const entities = [
    ...new Set(
      employees
        .sort((a, b) => sorters.string(a.entity, b.entity))
        .map(e => e.entity)
    )
  ];
  const majorCaps = [
    ...new Set(
      employees
        .sort((a, b) => sorters.string(a.major, b.major))
        .map(e => e.major)
    )
  ];
  const minorCaps = chain(employees)
    .flatMap(e => e.minor.split(EMPLOYEE_ACTIVE_DELIMITER))
    .map(minor => minor.trim())
    .filter(minor => minor !== "")
    .uniq()
    .sort()
    .value();

  const clients = chain(employees)
    .flatMap(e => e.clients.split(EMPLOYEE_ACTIVE_DELIMITER))
    .map(client => client.trim())
    .filter(client => client !== "")
    .uniq()
    .sort()
    .value();

  const projects = chain(employees)
    .flatMap(e => e.projects.split(EMPLOYEE_ACTIVE_DELIMITER))
    .map(project => project.trim())
    .filter(project => project !== "")
    .uniq()
    .sort()
    .value();

  const capabilities = chain(majorCaps.concat(minorCaps))
    .uniq()
    .sort()
    .value();

  const clearFilters = () => {
    form.resetFields();
    filterAdvancedEmployees(
      props,
      {},
      {
        searchTerm: "",
        selectedPractices: [],
        selectedBusinessUnits: [],
        selectedPositions: [],
        selectedLevels: [],
        selectedRegions: [],
        selectedLocations: [],
        selectedEntities: [],
        selectedCapabilities: [],
        selectedMinorOrMajor: [],
        selectedSkills: [],
        selectedSkillExpLevels: [],
        selectedSkillSearchType: false,
        selectedCertifications: [],
        selectedActive: [],
        selectedRoleTypes: [],
        selectedPRExpLevels: [],
        selectedPrompts: [],
        selectedClients: [],
        selectedProjects: [],
        selectedHours: "",
        selectedEndDate: ""
      }
    );
  };

  const handleEnter = e => {
    if (e.keyCode === 13) {
      onSubmit(advancedSearchFilteredEmployees);
    }
  };

  const { getFieldDecorator } = form;

  return (
    <div style={disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}>
      <Form layout="inline" onKeyDown={e => handleEnter(e)}>
        <Form.Item
          style={{ border: "0.1em solid #cfcfcf", borderRadius: "0.25em" }}
        >
          {getFieldDecorator("searchTerm", {
            initialValue: settings.search
          })(
            <Input
              prefix={<Icon type="filter" />}
              ref={searchRef}
              disabled={disabled}
              placeholder="Filter"
              style={{ width: "220px" }}
            />
          )}
        </Form.Item>
        <Form.Item
          style={{
            border: "0.1em solid #cfcfcf",
            borderRadius: "0.25em",
            marginBottom: "4px"
          }}
        >
          {getFieldDecorator("selectedPractices", {
            initialValue: settings.practice
          })(
            <Select
              showSearch
              mode="multiple"
              placeholder="Practice"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {practices.map(practice => (
                <Select.Option key={practice} value={practice}>
                  {practice}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          style={{
            border: "0.1em solid #cfcfcf",
            borderRadius: "0.25em",
            marginBottom: "4px"
          }}
        >
          {getFieldDecorator("selectedBusinessUnits", {
            initialValue: settings.businessUnit
          })(
            <Select
              showSearch
              mode="multiple"
              placeholder="Business Unit"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {businessUnits.map(businessUnit => (
                <Select.Option key={businessUnit} value={businessUnit}>
                  {businessUnit}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          style={{
            border: "0.1em solid #cfcfcf",
            borderRadius: "0.25em",
            marginBottom: "4px"
          }}
        >
          {getFieldDecorator("selectedPositions", {
            initialValue: settings.position
          })(
            <Select
              mode="multiple"
              placeholder="Position"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
              dropdownMatchSelectWidth={false}
            >
              {positions.map(position => (
                <Select.Option key={position} value={position}>
                  {position}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          style={{
            border: "0.1em solid #cfcfcf",
            borderRadius: "0.25em",
            marginBottom: "4px"
          }}
        >
          {getFieldDecorator("selectedLevels", {
            initialValue: settings.level
          })(
            <Select
              mode="multiple"
              placeholder="Level"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {levels.map(level => (
                <Select.Option key={level} value={level}>
                  {level}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          style={{
            border: "0.1em solid #cfcfcf",
            borderRadius: "0.25em",
            marginBottom: "4px"
          }}
        >
          {getFieldDecorator("selectedRegions", {
            initialValue: settings.region
          })(
            <Select
              mode="multiple"
              placeholder="Region"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
              dropdownMatchSelectWidth={false}
            >
              {regions.map(region => (
                <Select.Option key={region} value={region}>
                  {region}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          style={{
            border: "0.1em solid #cfcfcf",
            borderRadius: "0.25em",
            marginBottom: "4px"
          }}
        >
          {getFieldDecorator("selectedLocations", {
            initialValue: settings.location
          })(
            <Select
              mode="multiple"
              placeholder="Location"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
              dropdownMatchSelectWidth={false}
            >
              {locations.map(location => (
                <Select.Option key={location} value={location}>
                  {location}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          style={{
            border: "0.1em solid #cfcfcf",
            borderRadius: "0.25em",
            marginBottom: "4px"
          }}
        >
          {getFieldDecorator("selectedEntities", {
            initialValue: settings.entity
          })(
            <Select
              mode="multiple"
              placeholder="Entity"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
            >
              {entities.map(entity => (
                <Select.Option key={entity} value={entity}>
                  {entity}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          style={{ border: "0.1em solid #cfcfcf", borderRadius: "0.25em" }}
        >
          {getFieldDecorator("selectedPrompts", {
            initialValue: settings.prompt
          })(
            <TreeSelect
              multiple
              expandTrigger="hover"
              placeholder="Prompts"
              dropdownClassName="filter-bar-select-dropdown"
              className="filter-bar-select"
              dropdownStyle={{ maxHeight: 300, overflow: "auto" }}
            >
              {prompts
                .sort((a, b) => a.displayOrder - b.displayOrder)
                .map(prompt => {
                  return (
                    <TreeSelect.TreeNode
                      key={"prompt" + prompt.id}
                      value={"prompt" + prompt.id}
                      title={
                        (prompt.practiceName
                          ? prompt.practiceName + " - "
                          : "") + prompt.question
                      }
                      selectable={false}
                    >
                      {prompt.options
                        .sort((a, b) => a.displayOrder - b.displayOrder)
                        .map(option => {
                          return (
                            <TreeSelect.TreeNode
                              key={option.id}
                              value={`${prompt.id},${option.id}`}
                              title={option.response}
                            />
                          );
                        })}
                    </TreeSelect.TreeNode>
                  );
                })}
            </TreeSelect>
          )}
        </Form.Item>

        {!extraInfoHidden && (
          <Form.Item
            style={{ border: "0.1em solid #cfcfcf", borderRadius: "0.25em" }}
          >
            {getFieldDecorator("selectedClients", {
              initialValue: settings.client
            })(
              <Select
                mode="multiple"
                placeholder="Client"
                dropdownClassName="filter-bar-select-dropdown"
                className="filter-bar-select"
                dropdownMatchSelectWidth={false}
              >
                {clients.map(o => (
                  <Select.Option key={o} value={o}>
                    {o}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
        {!extraInfoHidden && (
          <Form.Item
            style={{ border: "0.1em solid #cfcfcf", borderRadius: "0.25em" }}
          >
            {getFieldDecorator("selectedProjects", {
              initialValue: settings.project
            })(
              <Select
                mode="multiple"
                placeholder="Project"
                dropdownClassName="filter-bar-select-dropdown"
                className="filter-bar-select"
                dropdownMatchSelectWidth={false}
              >
                {projects.map(o => (
                  <Select.Option key={o} value={o}>
                    {o}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
        {!extraInfoHidden && (
          <Form.Item
            style={{ border: "0.1em solid #cfcfcf", borderRadius: "0.25em" }}
          >
            {getFieldDecorator("selectedHours", {
              initialValue: settings.hours
            })(
              <InputNumber
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  width: "220px"
                }}
                placeholder="Available Hours"
              />
            )}
          </Form.Item>
        )}
        {!extraInfoHidden && (
          <Form.Item
            style={{ border: "0.1em solid #cfcfcf", borderRadius: "0.25em" }}
          >
            {getFieldDecorator("selectedEndDate", {
              initialValue:
                settings.endDate === "" || settings.endDate === null
                  ? null
                  : moment(settings.endDate)
            })(
              <DatePicker
                style={{ width: "220px" }}
                placeholder="Roll-Off Date"
                showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
                format="MM/DD/YYYY"
              />
            )}
          </Form.Item>
        )}

        <SelectStack>
          <Form.Item>
            {getFieldDecorator("selectedCapabilities", {
              initialValue: settings.capability
            })(
              <Select
                mode="multiple"
                placeholder="Capabilities/Specializations"
                dropdownClassName="filter-bar-select-dropdown"
                className="filter-bar-select"
                dropdownMatchSelectWidth={false}
              >
                {capabilities.map(c => (
                  <Select.Option key={c} value={c}>
                    {c}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          {getFieldDecorator("selectedMinorOrMajor", {
            initialValue: settings.minorOrMajor
          })(
            <Select size="small" mode="multiple" placeholder="Options">
              <Select.Option key={"Major"} value={"Major"}>
                Major Capability
              </Select.Option>
              <Select.Option key={"Minor"} value={"Minor"}>
                Minor Capability
              </Select.Option>
            </Select>
          )}
        </SelectStack>
        <SelectStack>
          <Form.Item>
            {getFieldDecorator("selectedSkills", {
              initialValue: settings.skills
            })(
              <TreeSelect
                multiple
                showSearch
                placeholder="Skills"
                treeNodeFilterProp="title"
                dropdownStyle={{ maxHeight: 300, overflow: "auto" }}
              >
                {skills.length > 0 &&
                  skills.map(skill => TreeSelectRecursive(skill, "children"))}
              </TreeSelect>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("selectedSkillExpLevels", {
              initialValue: settings.skillExpLevel
            })(
              <Select size="small" mode="multiple" placeholder="Options">
                {experienceLevels.map(el => (
                  <Select.Option key={el} value={el}>
                    {enumToDisplayText(el)}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          {getFieldDecorator("selectedSkillSearchType", {
            valuePropName: "checked",
            initialValue: settings.skillSearchType
          })(
            <Switch
              checkedChildren="AND"
              unCheckedChildren="OR"
              style={{ margin: 5 }}
            />
          )}
        </SelectStack>

        <SelectStack>
          <Form.Item>
            {getFieldDecorator("selectedRoleTypes", {
              initialValue: settings.roleType
            })(
              <TreeSelect
                multiple
                showSearch
                placeholder="Role Types"
                treeNodeFilterProp="title"
                dropdownStyle={{ maxHeight: 300, overflow: "auto" }}
              >
                {roleTypes.map(rt => TreeSelectRecursive(rt, "contains"))}
              </TreeSelect>
            )}
          </Form.Item>

          {getFieldDecorator("selectedPRExpLevels", {
            initialValue: settings.prExpLevel
          })(
            <Select size="small" mode="multiple" placeholder="Options">
              {prExperienceLevels.map(el => (
                <Select.Option key={el} value={el}>
                  {enumToDisplayText(el)}
                </Select.Option>
              ))}
            </Select>
          )}
        </SelectStack>

        <SelectStack>
          <Form.Item>
            {getFieldDecorator("selectedCertifications", {
              initialValue: settings.certification
            })(
              <TreeSelect
                showArrow={true}
                showSearch
                multiple
                placeholder="Certifications"
                treeNodeFilterProp="title"
                dropdownStyle={{ maxHeight: 300, overflow: "auto" }}
              >
                {certifyingOrgNames.map(certifyingOrgName => (
                  <TreeSelect.TreeNode
                    key={certifyingOrgName}
                    value={certifyingOrgName}
                    title={certifyingOrgName}
                    selectable={false}
                  >
                    {certifications
                      .filter(
                        c => c.certifyingOrganization.name === certifyingOrgName
                      )
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(certification => (
                        <TreeSelect.TreeNode
                          key={certification.id}
                          value={certification.id}
                          title={certification.name}
                          selectable={true}
                        />
                      ))}
                  </TreeSelect.TreeNode>
                ))}
              </TreeSelect>
            )}
          </Form.Item>
          {getFieldDecorator("selectedActive", {
            initialValue: settings.certActive
          })(
            <Select size="small" mode="multiple" placeholder="Options">
              <Select.Option
                key={"Include Inactive"}
                value={"Include Inactive"}
              >
                Include Inactive
              </Select.Option>
            </Select>
          )}
        </SelectStack>

        <Form.Item>
          <Button
            type="primary"
            onClick={() => onSubmit(advancedSearchFilteredEmployees)}
            style={{ marginTop: "19px" }}
          >
            Search
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            title="Clear search"
            icon="close"
            type="link"
            onClick={clearFilters}
            style={{ marginTop: "19px" }}
          >
            Clear Search
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

EmployeeAdvancedSearchForm.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  employees: PropTypes.array
};

export default Form.create({
  name: "employee_advanced_search_form",
  onValuesChange: debounce(filterAdvancedEmployees, 500)
})(EmployeeAdvancedSearchForm);
