import React, { useState } from "react";
import { Collapse, Icon, Table, Tag, Switch } from "antd";
import UserContext from "auth/UserContext";
import { useUser } from "auth/UserHooks";
import SkillCard from "components/skills/SkillCard";
import CardCollapse from "components/common/collapse/CardCollapse";
import AddNewCard from "components/common/AddNewCard";
import { Link } from "react-router-dom";
import formatDate from "utils/formatDate";
import EmployeeProjectTimeline from "./EmployeeProjectTimeline";
import EmployeePromptPanel from "./EmployeePromptPanel";
import EmployeeCertificationPanel from "./EmployeeCertificationPanel";
import PropTypes from "prop-types";
import EmployeeKeyMetrics from "./EmployeeKeyMetrics";
import CareerCoachCard from "../careerCoach/CareerCoachCard";
import EmployeeEntityCard from "../entities/EmployeeEntityCard";
import RoleTypeCard from "../RoleTypes/RoleTypeCard";
import { employeeType } from "types";
import { useFeatures } from "hooks/FeatureHooks";
import MajorAndMinorCard from "components/majorMinor/MajorAndMinorCard";
import MajorOrMinorCard from "components/majorMinor/MajorOrMinorCard";
import { getEmployee } from "api/employeeApi";

const EmployeeDetails = ({ employee = {}, projects = [] }) => {
  const user = useUser();
  const features = useFeatures();
  const isMe = employee.id === user.id;
  const isCoach = user.coachees.includes(employee.id);
  const isInPractice = user.practice === employee.practice;
  const activeRoleTypes = employee.roleTypes.filter(r => r.roleType.active);
  const { skills } = employee;
  const [major, setMajor] = useState(employee.major);
  const [minor, setMinor] = useState(employee.minor);
  // eslint-disable-next-line no-unused-vars
  const [showCompletedBadges, setShowCompletedBadges] = useState(false);
  const [staffingPreferencesPanelExtra, setStaffingPreferencesPanelExtra] = useState(null);
  const [additionalInfoPanelExtra, setAdditionalInfoPanelExtra] = useState(null);
  const [certifications, setCertifications] = useState(employee.certifications);
  const newSkillEmployeeLink = {
    pathname: "/employee/skills/new",
    state: { employeeId: employee.id }
  };
  const newRoleTypeEmployeeLink = {
    pathname: "/employee/role-types/new",
    state: { employeeId: employee.id }
  };

  const updateEmployee = async () => {
    const newEmployee = await getEmployee(employee.id);
    setMajor(newEmployee.major);
    setMinor(newEmployee.minor);
    return newEmployee;
  }

  const editEmployeeMajorMinor = {
    pathname: `/employee/major-minor/${employee.id}`,
    state: { employeeId: employee.id, employee: employee }
  }

  let activePanels = ["aboutMe", "staffingPreferences", "additionalInfo"];

  if (!Object.keys(employee).length) return null;

  // eslint-disable-next-line no-unused-vars
  const onChangeShowCompletedBadges = (checked, e) => {
    e.stopPropagation();
    setShowCompletedBadges(checked);
  };

  const levelToValue = exLevel => {
    switch (exLevel) {
      case "ADVANCED":
        return 3;
      case "INTERMEDIATE":
        return 2;
      case "BEGINNER":
        return 1;
      default:
        return 0;
    }
  };
  const sortByLvAndDate = (a, b) =>
    levelToValue(b.experienceLevel) - levelToValue(a.experienceLevel) ||
    new Date(b.lastUsedDate) - new Date(a.lastUsedDate);

  return (
    <UserContext.Consumer>
      {ctx => (
        <Collapse bordered={false} defaultActiveKey={activePanels}>
          {((ctx.user.permissions.canUpdateOwnPromptResponse && isMe) ||
            (isCoach && ctx.user.permissions.canUpdateCoacheePromptResponse) ||
            ctx.user.permissions.canReadPromptResponses) && (
              <CardCollapse.Panel
                header={
                  <>
                    <Icon type="question-circle" /> About Me
                  </>
                }
                key="aboutMe"
              >
                {ctx.user.permissions.canManageEmployees && (
                  <EmployeeEntityCard
                    employee={employee}
                    isEditable={ctx.user.permissions.canManageEmployees}
                  />
                )}
                {(!!employee.coach || employee.coachees.length > 0) && (
                  <CareerCoachCard employee={employee} />
                )}
                <MajorAndMinorCard
                  employee={employee}
                  isMe={isMe}
                  canEdit={ctx.user.permissions.canCreateMajorMinor}
                  isAdmin={ctx.user.permissions.canManageEmployees}
                  onCloseForm={updateEmployee}
                />
              </CardCollapse.Panel>
            )}
          <CardCollapse.Panel
            header={
              <>
                <Icon type="schedule" /> {isMe ? "My " : ""}Staffing Preferences
              </>
            }
            key="staffingPreferences"
            extra={staffingPreferencesPanelExtra}
          >
          </CardCollapse.Panel>
          <CardCollapse.Panel
            header={
              <>
                <Icon type="info-circle" /> {isMe ? "My " : ""}Additional Information
              </>
            }
            key="additionalInfo"
            extra={additionalInfoPanelExtra}
          >
            <EmployeePromptPanel
              employeeId={employee.id}
              isMe={isMe}
              isCoach={isCoach}
              categories={[
                { category: "", callback: null },
                {
                  category: "STAFFING_PREFERENCES",
                  callback: setStaffingPreferencesPanelExtra,
                },
                {
                  category: "ADDITIONAL_INFORMATION",
                  callback: setAdditionalInfoPanelExtra,
                },
              ]}
            />
          </CardCollapse.Panel>
          {features &&
            !features.isEnabled("DISABLE_KEY_METRICS") &&
            (ctx.user.permissions.canReadKeyMetrics ||
              (isMe && ctx.user.permissions.canReadOwnKeyMetrics) ||
              (isInPractice &&
                ctx.user.permissions.canReadPracticeKeyMetrics) ||
              (isCoach && ctx.user.permissions.canReadCoacheeKeyMetrics)) && (
              <CardCollapse.Panel
                header={
                  <>
                    <Icon type="key" /> {isMe ? "My " : ""}Key Metrics
                  </>
                }
                key="keyMetrics"
              >
                <EmployeeKeyMetrics employeeId={employee.id} />
              </CardCollapse.Panel>
            )}

          {(major || minor || ctx.user.permissions.canCreateMajorMinor) && ctx.user.permissions.canReadMajorMinor && (
            <CardCollapse.Panel
              key="majorMinor"
              header={
                <>
                  <Icon type="bulb" /> {isMe ? "My " : ""}Major and Minor
                </>
              }
              extra={
                <Tag>
                  {major && minor ? "major and minor" : major || minor ? "major or minor" : "no major or minor"}
                </Tag>
              }
            >
              {(isMe || ctx.user.permissions.canReadMajorMinor) &&
                major
                ?
                <CardCollapse.Card key={major.id}>
                  <MajorOrMinorCard
                    majorMinor={major}
                    major={"Major"}
                    isCapability={major.isCapability}
                  />
                </CardCollapse.Card>
                : null}
              {(isMe || ctx.user.permissions.canReadMajorMinor) &&
                minor
                ?
                <CardCollapse.Card key={minor.id}>
                  <MajorOrMinorCard
                    majorMinor={minor}
                    major={"Minor"}
                    isCapability={minor.isCapability}
                  />
                </CardCollapse.Card>
                : null}
              {(ctx.user.permissions.canCreateMajorMinor) && (
                <CardCollapse.Card>
                  <AddNewCard
                    title="Edit Major and Minor"
                    link={editEmployeeMajorMinor}
                  />
                </CardCollapse.Card>
              )}
            </CardCollapse.Panel>
          )}

          {ctx.user.permissions.canReadSkills && (
            <CardCollapse.Panel
              key="skills"
              header={
                <>
                  <Icon type="compass" /> {isMe ? "My " : ""}Skills
                </>
              }
              extra={
                <Tag>
                  {skills && skills.length} Skill
                  {skills && skills.length === 1 ? "" : "s"}
                </Tag>
              }
            >
              {(isMe || ctx.user.permissions.canReadSkills) && skills
                ? skills.sort(sortByLvAndDate).map(skill => (
                  <CardCollapse.Card key={skill.skill.id}>
                    <SkillCard
                      employeeId={employee.id}
                      skill={skill.skill}
                      employeeSkill={skill}
                    />
                  </CardCollapse.Card>
                ))
                : null}
              {((ctx.user.permissions.canCreateOwnSkill && isMe) ||
                (isCoach && ctx.user.permissions.canCreateCoacheeSkill) ||
                ctx.user.permissions.canCreateSkill) && (
                  <CardCollapse.Card>
                    <AddNewCard title="Add skill" link={newSkillEmployeeLink} />
                  </CardCollapse.Card>
                )}
              {!isMe && (!skills || !skills.length) && "No skills"}
            </CardCollapse.Panel>
          )}

          {ctx.user.permissions.canReadRoleTypes && (
            <CardCollapse.Panel
              key="activeRoleTypes"
              header={
                <>
                  <Icon type="team" /> {isMe ? "My " : ""}Role Types
                </>
              }
              extra={
                <Tag>
                  {activeRoleTypes && activeRoleTypes.length} Role Type
                  {activeRoleTypes && activeRoleTypes.length === 1 ? "" : "s"}
                </Tag>
              }
            >
              {(isMe || ctx.user.permissions.canReadRoleTypes) &&
                activeRoleTypes
                ? activeRoleTypes.sort(sortByLvAndDate).map(roleType => (
                  <CardCollapse.Card key={roleType.roleType.id}>
                    <RoleTypeCard
                      employeeId={employee.id}
                      roleType={roleType.roleType}
                      employeeRoleType={roleType}
                    />
                  </CardCollapse.Card>
                ))
                : null}
              {((ctx.user.permissions.canCreateOwnRoleType && isMe) ||
                (isCoach && ctx.user.permissions.canCreateCoacheeRoleType) ||
                ctx.user.permissions.canCreateRoleType) && (
                  <CardCollapse.Card>
                    <AddNewCard
                      title="Add Role Type"
                      link={newRoleTypeEmployeeLink}
                    />
                  </CardCollapse.Card>
                )}
              {!isMe &&
                (!activeRoleTypes || !activeRoleTypes.length) &&
                "No Role Types"}
            </CardCollapse.Panel>
          )}

          <CardCollapse.Panel
            key="certifications"
            header={
              <>
                <Icon type="solution" /> {isMe ? "My " : ""}Certifications
              </>
            }
            extra={
              <Tag>
                {certifications.length}{" "}
                {certifications.length === 1
                  ? "certification"
                  : "certifications"}
              </Tag>
            }
          >
            <EmployeeCertificationPanel
              employeeId={employee.id}
              isMe={isMe}
              isCoach={isCoach}
              callback={setCertifications}
            />
          </CardCollapse.Panel>

          {/*{ctx.user.permissions.canViewBadges && (*/}
          {/*  <CardCollapse.Panel*/}
          {/*    key="badges"*/}
          {/*    header={*/}
          {/*      <>*/}
          {/*        <Icon type="safety-certificate" /> {isMe ? 'My ' : ''}Badges*/}
          {/*      </>*/}
          {/*    }*/}
          {/*    extra={*/}
          {/*      <>*/}
          {/*        <Switch*/}
          {/*          checkedChildren="Completed"*/}
          {/*          unCheckedChildren="Assigned"*/}
          {/*          defaultChecked={showCompletedBadges}*/}
          {/*          onChange={onChangeShowCompletedBadges}*/}
          {/*        />*/}
          {/*        &nbsp;*/}
          {/*        <Tag color={!showCompletedBadges && assignedBadges.length > 0 ? 'red' : ''}>*/}
          {/*          {showCompletedBadges ? completedBadges.length : assignedBadges.length} Badge*/}
          {/*          {(showCompletedBadges ? completedBadges.length : assignedBadges.length) === 1 ? '' : 's'}*/}
          {/*        </Tag>*/}
          {/*      </>*/}
          {/*    }*/}
          {/*  >*/}
          {/*    {assignedBadges && !showCompletedBadges*/}
          {/*      ? assignedBadges.map(badge => (*/}
          {/*          <CardCollapse.Card key={badge.id}>*/}
          {/*            <BadgeCard badge={badge.badge} assignedOn={badge.assignedOn} isMe={isMe} isCoach={isCoach} />*/}
          {/*          </CardCollapse.Card>*/}
          {/*        ))*/}
          {/*      : null}*/}
          {/*    {completedBadges && showCompletedBadges*/}
          {/*      ? completedBadges.map(badge => (*/}
          {/*          <CardCollapse.Card key={badge.id}>*/}
          {/*            <BadgeCard*/}
          {/*              badge={badge.badge}*/}
          {/*              assignedOn={badge.assignedOn}*/}
          {/*              issuedOn={badge.issuedOn}*/}
          {/*              isMe={isMe}*/}
          {/*              isCoach={isCoach}*/}
          {/*            />*/}
          {/*          </CardCollapse.Card>*/}
          {/*        ))*/}
          {/*      : null}*/}
          {/*    {(!badges || !badges.length) && 'No badges'}*/}
          {/*  </CardCollapse.Panel>*/}
          {/*)}*/}

          {projects &&
            projects.length &&
            ctx.user.permissions.canReadProjects && (
              <ProjectsDetails projects={projects} employee={employee} />
            )}
        </Collapse>
      )}
    </UserContext.Consumer>
  );
};

EmployeeDetails.defaultProps = {
  employee: {}
};

EmployeeDetails.propTypes = {
  employee: employeeType
};

export default EmployeeDetails;

const ProjectsDetails = ({ projects = [], employee = {}, ...props }) => {
  const [showProjectTimeline, setShowProjectTimeline] = useState(false); // TODO: Remember user's preference in local storage
  const user = useUser();
  const isMe = employee.id === user.id;
  const projectColumns = [
    {
      title: "Client",
      dataIndex: "project.client",
      render: record => <Link to={`/clients/${record.id}`}>{record.name}</Link>
    },
    {
      title: "Project",
      dataIndex: "project.name",
      render: (name, record) => (
        <Link to={`/projects/${record.project.id}`}>{name}</Link>
      )
    },
    {
      title: "Role",
      dataIndex: "role"
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      render: formatDate,
      sorter: (a, b) => (a.startDate < b.startDate ? -1 : 1)
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      render: formatDate,
      defaultSortOrder: "descend",
      sorter: (a, b) => (a.endDate < b.endDate ? -1 : 1)
    },
    {
      title: "Hours / Week",
      dataIndex: "hoursPerWeek",
      width: 120,
      align: "right"
    },
    {
      title: "Active",
      dataIndex: "active",
      align: "center",
      render: record => (
        <Tag color={record ? "green" : "red"}>
          {record ? "Active" : "Inactive"}
        </Tag>
      ),
      sorter: (a, b) => (a.active < b.active ? -1 : 1)
    }
  ];

  const onChangeShowProjectTimeline = (checked, e) => {
    e.stopPropagation();
    setShowProjectTimeline(checked);
  };

  return (
    <>
      {projects && projects.length ? (
        <CardCollapse.Panel
          {...props}
          key="projects"
          header={
            <>
              <Icon type="project" /> {isMe ? "My " : ""}Projects
            </>
          }
          extra={
            <>
              <Switch
                checkedChildren="Table"
                unCheckedChildren="Timeline"
                defaultChecked={showProjectTimeline}
                onChange={onChangeShowProjectTimeline}
              />
              &nbsp;
              <Tag>
                {projects.length} Project{projects.length === 1 ? "" : "s"}
              </Tag>
            </>
          }
        >
          {showProjectTimeline && projects.length && (
            <EmployeeProjectTimeline projects={projects} />
          )}
          {!showProjectTimeline && (
            <Table
              style={{ width: "100%" }}
              pagination={{ position: "none", pageSize: projects.length }}
              rowKey={record => record.project.id}
              columns={projectColumns}
              dataSource={projects}
            />
          )}
        </CardCollapse.Panel>
      ) : null}
    </>
  );
};

ProjectsDetails.defaultProps = {
  projects: [],
  employee: {}
};

ProjectsDetails.propTypes = {
  employee: employeeType,
  projects: PropTypes.array
};
