import React, { useEffect, useState, useRef } from 'react'
import { Collapse, Typography, Table, Tag, Spin, Button, Input } from 'antd'
import { get, find } from 'lodash'
import UserContext from 'auth/UserContext'
import { Link, RouteComponentProps } from 'react-router-dom'
import CardCollapse from 'components/common/collapse/CardCollapse'
import Stack from 'components/common/Stack'
import displayErrorNotification from 'utils/displayErrorNotification'
import { showPractice } from 'utils/skillDetails'
import { getLandingPageCapability } from 'api/capabilitiesApi'
import { EmployeeWithPermissions, useUser } from 'auth/UserHooks'
import { trackComponent } from 'telemetry/AppInsights'
import CapabilityFilters, { filterCapabilities } from 'components/capabilities/CapabilityFilters'
import Toolbar from 'components/common/Toolbar'
import { uniq, map } from 'lodash'
import ButtonRow from 'components/common/ButtonRow'
import { ColumnProps } from 'antd/lib/table'
import { Capability } from 'types/Capability'
import SavePreferencesButton from 'components/common/SavePreferencesButton'
import useLocalStorage from 'utils/useLocalStorage'
import {
  defaultCapabilitySortSettings,
  defaultCapabilityFilterSettings,
  CapabilityFilterSettings
} from 'types/CapabilityPreferences'
import { PracticeSortSettings } from 'types/ColumnSortSettings'
import { useFeatures } from 'hooks/FeatureHooks'
import { getEmployeePractices } from 'api/employeePracticeApi'
import { convertLandingsPageCapability } from 'utils/convert'
import { EmployeePractice } from 'types/EmployeePractice'

const CapabilitiesPage = (props: RouteComponentProps) => {
  const features = useFeatures()
  const [capabilities, setCapabilities] = useState<Capability[]>([])
  const [filteredCapabilities, setFilteredCapabilities] = useState<Capability[]>([])
  const [employeePractices, setEmployeePractices] = useState<EmployeePractice[]>([])
  const [practices, setPractices] = useState<number[]>([])
  const [activePanels, setActivePanels] = useState<string[]>([])
  const user = useUser()
  const [loading, setLoading] = useState(false)
  const [filterSettings, setFilterSettings] = useLocalStorage<CapabilityFilterSettings>(
    'CapabilitiesLandingPage_FilterSettings',
    defaultCapabilityFilterSettings()
  )
  const [sortSettings, setSortSettings] = useLocalStorage<PracticeSortSettings>(
    'CapabilitiesLandingPage_SortSettings',
    defaultCapabilitySortSettings()
  )
  const searchRef = useRef<Input>()

  useEffect(() => {
    setLoading(true)
    Promise.all([getLandingPageCapability(), getEmployeePractices()])
      .then(response => {
        let converted = convertLandingsPageCapability(response[0])
        let sortedPractices = getSortedCategoriesByUser(user, uniq(map(converted, 'employeePracticeId')), response[1])
        setCapabilities(converted)
        setEmployeePractices(response[1])
        setPractices(sortedPractices)
        filterCapabilities(
          {
            onSearch: handleFilter,
            capabilities: converted,
            settings: filterSettings,
            setSettings: setFilterSettings,
            disabled: loading,
            form: null,
            searchRef: null,
            employeePractices: response[1],
            practices: sortedPractices
          },
          {},
          {
            searchTerm: filterSettings.search,
            selectedPractices: filterSettings.practice,
            showInactive: filterSettings.inActive
          }
        )

      })
      .catch(displayErrorNotification)
      .then(() => {
        setLoading(false)
        if (searchRef.current !== undefined && searchRef.current.input !== null) {
          searchRef.current.focus()
        }
      })

  }, [])

  const getSortedCategoriesByUser = (user: EmployeeWithPermissions, usedPractices: number[], employeePractices: EmployeePractice[]) => {
    const practiceName = get(user, 'practice', '')
    let categories: number[] = []
    const ep: EmployeePractice | undefined = find(employeePractices, { abbrev: practiceName })
    if (ep !== undefined) {
      if (usedPractices.includes(ep.id)) {
        categories.push(ep.id)
      }
    }
    employeePractices.forEach(p => {
      if (ep === undefined || p.id !== ep.id) {
        if (usedPractices.includes(p.id)) {
          categories.push(p.id)
        }
      }
    })
    return categories
  }

  const handlePanelChange = (panels: string[] | string) => {
    if (panels instanceof Array) {
      setActivePanels(panels)
    } else {
      setActivePanels([panels])
    }
  }

  const handleFilter = (_filteredCapabilities: Capability[]) => {
    setFilteredCapabilities(_filteredCapabilities)
    const panelIds = uniq(map(_filteredCapabilities, 'employeePracticeId')).map(String)
    setActivePanels(panelIds)
  }

  const columns: ColumnProps<Capability>[] = [
    {
      title: 'Capability/Specialization',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      render: (text, record) => <Link to={`/capabilities/${record.id}`}>{record.name}</Link>,
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      onHeaderCell: () => {
        return {
          style: { position: 'absolute', left: 0, width: 'calc(30%)' }
        }
      }
    },
    {
      title: 'Employees',
      dataIndex: 'employeeCount',
      key: 'employeeCount',
      width: '150px',
      align: 'right'
    },
    {
      title: 'Major',
      dataIndex: 'majorCount',
      key: 'majorCount',
      width: '150px',
      align: 'right'
    },
    {
      title: 'Minor',
      dataIndex: 'minorCount',
      key: 'minorCount',
      width: '150px',
      align: 'right'
    },
    {
      title: 'Active',
      dataIndex: 'inactive',
      key: 'inactive',
      width: '150px',
      align: 'right',
      render: inactive => <Tag color={inactive ? 'red' : 'green'}>{inactive ? 'Inactive' : 'Active'}</Tag>,
      sorter: (a, b) => (a.inactive < b.inactive ? 1 : -1)
    }
  ]

  function onChange(_pagination: any, _filters: any, sorter: any) {
    if (Object.keys(sorter).length > 0) {
      const find = sortSettings.practices.find(o => {
        return o.practice === sorter.column.practice
      })
      if (find === undefined) {
        sortSettings.practices.push({
          practice: sorter.column.practice,
          column: {
            key: sorter.columnKey,
            order: sorter.order
          }
        })
      } else {
        find.column.key = sorter.columnKey
        find.column.order = sorter.order
      }
      setSortSettings(sortSettings)
    }
  }

  return (
    <UserContext.Consumer>
      {ctx => (
        <Stack>
          <div>
            <Typography.Title level={1}>
              Capabilities/Specializations
              <ButtonRow style={{ float: 'right' }}>
                {ctx.user.permissions.canCreateCapabilityType && (
                  <Button icon="solution" onClick={() => props.history.push('/capabilities/new')}>
                    Add Capability/Specialization Type
                  </Button>
                )}
                <SavePreferencesButton
                  keys={['CapabilitiesLandingPage_FilterSettings', 'CapabilitiesLandingPage_SortSettings']}
                  disabled={loading}
                />
              </ButtonRow>
            </Typography.Title>
            <Typography.Paragraph>
              The below inventory reflects the capability/specialization groups that exist across Credera's various practices. Click on
              a capability/specialization to view all of the employees aligned to that group.
            </Typography.Paragraph>
            {features && features.isEnabled('ENABLE_PROJECTOR_FEATURES') && (
              <Typography.Paragraph>
                Major Capabilities/Specializations are stored as Resource Cost Centers in Projector. Minor Capabilities/Specializations are periodically
                synced with Projector as Minor Capability/Specialization "Skills". Projector Minor Capability/Specialization of 0 = Not aligned to
                Minor, 5 = Aligned to Minor.
              </Typography.Paragraph>
            )}
            <Toolbar>
              <CapabilityFilters
                settings={filterSettings}
                setSettings={setFilterSettings}
                disabled={loading}
                onSearch={handleFilter}
                capabilities={capabilities}
                searchRef={searchRef}
                employeePractices={employeePractices}
                practices={practices}
              />
              <div>
                Showing {filteredCapabilities.length} of {capabilities.length} Capabilities/Specializations
              </div>
            </Toolbar>
          </div>

          <Spin spinning={loading}>
            <Collapse bordered={false} onChange={handlePanelChange} activeKey={activePanels}>
              {practices.map(employeePracticeId => {
                const categoryCapabilities = filteredCapabilities.filter(c => c.employeePracticeId === employeePracticeId)
                const categoryCapabilitiesCount = categoryCapabilities.length

                const columnsSortSettings: ColumnProps<Capability>[] = columns.map(column => {
                  const find = sortSettings.practices.find(o => {
                    return o.practice === employeePracticeId
                  })
                  if (find !== undefined) {
                    if (column.key === find.column.key) {
                      return {
                        ...column,
                        defaultSortOrder: find.column.order,
                        practice: employeePracticeId
                      }
                    }
                  } else {
                    if (column.key === sortSettings.default.key) {
                      return { ...column, defaultSortOrder: sortSettings.default.order, practice: employeePracticeId }
                    }
                  }
                  return { ...column, practice: employeePracticeId }
                })

                return (
                  <CardCollapse.Panel
                    header={<>{showPractice(employeePractices, employeePracticeId)}</>}
                    extra={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tag>{`${categoryCapabilitiesCount} ${categoryCapabilitiesCount === 1 ? 'Capability/Specialization' : 'Capabilities/Specializations'
                          }`}</Tag>
                      </div>
                    }
                    key={employeePracticeId}
                  >
                    <Table
                      rowKey="id"
                      style={{ width: '100%' }}
                      dataSource={categoryCapabilities}
                      columns={columnsSortSettings}
                      pagination={false}
                      onChange={onChange}
                    />
                  </CardCollapse.Panel>
                )
              })}
            </Collapse>
          </Spin>
        </Stack>
      )}
    </UserContext.Consumer>
  )
}

export default trackComponent(CapabilitiesPage, 'Capabilities/Specializations Landing Page')
