import { Button, Spin, Tag, Typography } from 'antd'
import { getLandingPageCapability } from 'api/capabilitiesApi'
import { getLandingPageIndustry } from 'api/industriesApi'
import { getCertifications } from 'api/employeeCertificationApi'
import { getProjectorSkills } from 'api/projectorApi'
import { getAllRoleTypes, getRoleTypeFamilies } from 'api/RoleTypeApi'
import { getLandingPageSkill, getSkillCategories } from 'api/skillsApi'
import Stack from 'components/common/Stack'
import { RecursiveTable } from 'components/skills/skill/SkillTable'
import { uniq } from 'lodash'
import React, { useEffect, useState } from 'react'
import { ProjectorSkill, RawResponse } from 'types/Projector'
import displayErrorNotification from 'utils/displayErrorNotification'
import { ColumnProps } from 'antd/lib/table'
import Toolbar from 'components/common/Toolbar'
import { ProjectorConstants as Const } from 'types/Projector'
import ButtonRow from '../../common/ButtonRow'
import moment from 'moment'
import { Parser } from 'json2csv'
import { LandingPageSkill } from 'types/Skill'
import { getEmployeePractices } from 'api/employeePracticeApi'

const ProjectorLandingPage = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [expandedRows, setExpandedRows] = useState([])
  const showSync = false;

  const [rawResponse, setRawResponse] = useState<RawResponse>({
    projectorSkills: [],
    capabilitiesAPI: [],
    capabilities: [],
    industriesAPI: [],
    industries: [],
    roleTypes: [],
    skillsAPI: [],
    skills: [],
    certifications: [],
    skillCategories: [],
    roleTypeFamily: [],
    employeePractices: []
  })

  const [projectorSkills, setProjectorSkills] = useState<ProjectorSkill[]>([])

  const _refreshProjectorSkills = () => {
    setLoading(true)
    return getProjectorSkills()
      .then(response => {
        const _rawResponse: RawResponse = {
          projectorSkills: response !== undefined ? response : [],
          capabilitiesAPI: rawResponse.capabilitiesAPI,
          capabilities: rawResponse.capabilities,
          industriesAPI: rawResponse.industriesAPI,
          industries: rawResponse.industries,
          roleTypes: rawResponse.roleTypes,
          skillsAPI: rawResponse.skillsAPI,
          skills: rawResponse.skills,
          certifications: rawResponse.certifications,
          skillCategories: rawResponse.skillCategories,
          roleTypeFamily: rawResponse.roleTypeFamily,
          employeePractices: rawResponse.employeePractices
        }
        setRawResponse(_rawResponse)
        _updateData(_rawResponse)
      })
      .catch(displayErrorNotification)
      .then(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    load()
  }, [])

  const getProjectorSkillTree = (skills: ProjectorSkill[]) => {
    const groups: string[] = uniq(skills.map(s => s.groupName)).sort()
    const tree: ProjectorSkill[] = []
    let groupInd = 0

    function projectorSkillFilter(projectorSkills: ProjectorSkill[], group: String) {
      let filteredProjectorSkill = projectorSkills.filter(s => s.groupName === group)
      return filteredProjectorSkill
    }
    for (let group of groups) {
      const groupContainer: ProjectorSkill = {
        name: group,
        id: 10000 + groupInd,
        contains: [],
        groupName: '',
        entity: '',
        tableId: 0,
        isDeleted: false
      }
      groupInd++
      const filteredProjectorSkill = projectorSkillFilter(skills, group)
      for (let skill of filteredProjectorSkill) {
        if (groupContainer.contains !== undefined) {
          groupContainer.contains.push(skill)
        }
      }
      tree.push(groupContainer)
    }
    return tree
  }

  const load = () => {
    setLoading(true)
    Promise.all([
      getProjectorSkills(),
      getLandingPageCapability(),
      getLandingPageIndustry(),
      getAllRoleTypes(),
      getLandingPageSkill(),
      getCertifications(true),
      getSkillCategories(),
      getRoleTypeFamilies(),
      getEmployeePractices(),
    ])
      .then(response => {
        const _rawResponse: RawResponse = {
          projectorSkills: response[0] !== undefined ? response[0] : [],
          capabilitiesAPI: response[1] !== undefined ? response[1] : [],
          capabilities: [],
          industriesAPI: response[2] !== undefined ? response[2] : [],
          industries: [],
          roleTypes: response[3] !== undefined ? response[3] : [],
          skillsAPI: response[4] !== undefined ? response[4] : [],
          skills: [],
          certifications: response[5] !== undefined ? response[5] : [],
          skillCategories: response[6] !== undefined ? response[6] : [],
          roleTypeFamily: response[7] !== undefined ? response[7] : [],
          employeePractices: response[8] !== undefined ? response[8] : []
        }
        setRawResponse(_rawResponse)
        _updateData(_rawResponse)
        setLoading(false)
      })
      .catch(displayErrorNotification)
  }

  const _updateData = (resp: RawResponse) => {
    setProjectorSkills(getProjectorSkillTree(resp.projectorSkills))
  }

  const _setExpandedRows = skills => {
    const groups = skills.map(s => s.id)
    setExpandedRows(groups)
  }

  const toggleExpandAll = () => {
    const expanded = expandedRows.length > 0
    _setExpandedRows(expanded ? [] : projectorSkills)
  }

  const showSkill = (skill: LandingPageSkill): string => {
    let prevSkill: LandingPageSkill | null = skill
    let skills: string[] = []
    while (prevSkill !== null) {
      skills.push(prevSkill.name)
      prevSkill = prevSkill.parentSkill
    }
    skills.push(skill.category)
    return skills.reverse().join(' > ')
  }

  let columns: ColumnProps<ProjectorSkill>[] = [
    {
      title: 'Projector Group / Skill',
      dataIndex: 'name',
      key: 'id',
      width: '33%',
      render: (text, record) => {
        if (record.contains && record.contains.length > 0) {
          return record.name
        } else {
          if (record.isDeleted === true) {
            return (
              <span>
                {record.name}&nbsp;<Tag color="red">Inactive</Tag>
              </span>
            )
          } else {
            return <span>{record.name}</span>
          }
        }
      },
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      onHeaderCell: () => {
        return {
          style: { position: 'absolute', left: 0, width: 'calc(33% + 50px)' }
        }
      }
    },
    {
      title: 'Entity',
      dataIndex: 'entity',
      key: 'entity',
      width: '8%',
      onHeaderCell: () => {
        return {
          style: { paddingLeft: '40px' }
        }
      }
    },
    {
      title: 'Value',
      key: 'value',
      width: '59%',
      onHeaderCell: () => {
        return {
          style: { paddingLeft: '35px' }
        }
      },
      render: (item: ProjectorSkill) => {
        if (item.contains === undefined) {
          if (item.tableId > 0) {
            switch (item.entity) {
              case Const.ENTITY_SKILL: {
                const find = rawResponse.skills.find(s => s.id === item.tableId)
                if (find !== undefined && find !== null) {
                  let out: string = showSkill(find)
                  if (find.active === false) {
                    return (
                      <>
                        {out}&nbsp;<Tag color="red">Inactive</Tag>
                      </>
                    )
                  } else {
                    return out
                  }
                } else {
                  return 'Skill not found #' + item.tableId
                }
              }
              case Const.ENTITY_CAPABILITY: {
                const find = rawResponse.capabilities.find(c => c.id === item.tableId)
                if (find !== undefined && find !== null) {
                  const findPractice = rawResponse.employeePractices.find(p => p.id === find.employeePracticeId)
                  if (findPractice !== undefined && findPractice !== null) {
                    let out: string = findPractice.name + ' [' + findPractice.abbrev + '] > ' + find.name
                    if (find.inactive === true) {
                      return (
                        <>
                          {out}&nbsp;<Tag color="red">Inactive</Tag>
                        </>
                      )
                    } else {
                      return out
                    }
                  } else {
                    return 'Employee Practice not found: ' + find.name
                  }
                } else {
                  return 'Capability/Specialization not found #' + item.tableId
                }
              }
              case Const.ENTITY_INDUSTRY_MAJOR: {
                const find = rawResponse.industries.find(i => i.id === item.tableId)
                if (find !== undefined && find !== null) {
                  let out: string = find.name
                  if (find.inactive === true) {
                    return (
                      <>
                        {out}&nbsp;<Tag color="red">Inactive</Tag>
                      </>
                    )
                  } else {
                    return out
                  }
                } else {
                  return 'Industry not found #' + item.tableId
                }
              }
              case Const.ENTITY_INDUSTRY_MINOR: {
                const find = rawResponse.industries.find(i => i.id === item.tableId)
                if (find !== undefined && find !== null) {
                  let out: string = find.name
                  if (find.inactive === true) {
                    return (
                      <>
                        {out}&nbsp;<Tag color="red">Inactive</Tag>
                      </>
                    )
                  } else {
                    return out
                  }
                } else {
                  return 'Industry not found #' + item.tableId
                }
              }
              case Const.ENTITY_ROLE_TYPE: {
                const find = rawResponse.roleTypes.find(r => Number(r.id) === item.tableId)
                if (find !== undefined && find !== null) {
                  const findFamily = rawResponse.roleTypeFamily.find(f => f.id === find.family_id)
                  if (findFamily !== undefined && findFamily !== null) {
                    let out: string = findFamily.name + ' > ' + find.name
                    if (find.active === false) {
                      return (
                        <>
                          {out}&nbsp;<Tag color="red">Inactive</Tag>
                        </>
                      )
                    } else {
                      return out
                    }
                  } else {
                    return 'Role Type Family not found: ' + find.name
                  }
                } else {
                  return 'Role Type not found #' + item.tableId
                }
              }
              case Const.ENTITY_CERTIFICATION: {
                const find = rawResponse.certifications.find(c => c.id === item.tableId)
                if (find !== undefined && find !== null) {
                  let out: string = find.certifyingOrganization.name + ' > ' + find.name
                  if (find.inactive === true) {
                    return (
                      <>
                        {out}&nbsp;<Tag color="red">Inactive</Tag>
                      </>
                    )
                  } else {
                    return out
                  }
                } else {
                  return 'Certifaction not found #' + item.tableId
                }
              }
              default:
                return 'Unknown entity type [' + item.entity + ']'
            }
          }
        }
      }
    }
  ]

  function onChange(_pagination, _filters, _sorter) { }

  const getEntityName = item => {
    if (item.entity === Const.ENTITY_CAPABILITY) {
      const find = rawResponse.capabilities.find(c => c.id === item.tableId)
      if (find !== undefined && find !== null) {
        const findPractice = rawResponse.employeePractices.find(p => p.id === find.employeePracticeId)
        return findPractice !== undefined && findPractice !== null ? findPractice.name + ' [' + findPractice.abbrev + '] > ' + find.name : ''
      }
      return ''
    } else if (item.entity === Const.ENTITY_INDUSTRY_MAJOR) {
      const find = rawResponse.industries.find(i => i.id === item.tableId)
      if (find !== undefined && find !== null) {
        return find.name
      }
      return ''
    } else if (item.entity === Const.ENTITY_INDUSTRY_MINOR) {
      const find = rawResponse.industries.find(i => i.id === item.tableId)
      if (find !== undefined && find !== null) {
        return find.name
      }
      return ''
    } else if (item.entity === Const.ENTITY_CERTIFICATION) {
      const find = rawResponse.certifications.find(c => c.id === item.tableId)
      return find !== undefined && find !== null ? find.certifyingOrganization.name + ' > ' + find.name : ''
    } else if (item.entity === Const.ENTITY_ROLE_TYPE) {
      const find = rawResponse.roleTypes.find(r => Number(r.id) === item.tableId)
      if (find !== undefined && find !== null) {
        const findFamily = rawResponse.roleTypeFamily.find(f => f.id === find?.family_id)
        return findFamily !== undefined && findFamily !== null ? findFamily?.name + ' > ' + find.name : ''
      }
      return ''
    } else if (item.entity === Const.ENTITY_SKILL) {
      const find = rawResponse.skills.find(s => s.id === item.tableId)
      return find !== undefined && find !== null ? showSkill(find) : ''
    }

    return ''
  }

  const exportProjectorSkills = () => {
    const entities = projectorSkills.flatMap(ps => ps.contains || [])
    const exportable = entities.map(k => {
      return {
        'Projector Skill Group Name': k.groupName,
        'Projector Skill Name': k.name,
        'Scout Entity Type': k.entity,
        'Scout Entity Name': getEntityName(k)
      }
    })

    const json2CsvParser = new Parser()
    const csv = json2CsvParser.parse(exportable)
    // @ts-ignore
    const blob = new Blob([csv], { type: 'text/csv', encoding: 'UTF-8' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `ProjectorSkills-${moment().format('YYYYMMDD-hhmmss')}.csv`
    link.click()
  }

  return (
    <Stack>
      {!loading && (
        <ButtonRow style={{ float: 'right' }}>
          {showSync && (
            <Button icon="sync" onClick={() => {}}>
              Sync
            </Button>
          )}
          <Button icon="download" onClick={exportProjectorSkills}>
            Export
          </Button>
        </ButtonRow>
      )}
      <Typography.Title>Projector</Typography.Title>
      <Typography.Paragraph>
        The Projector Skills below are linked to the following Scout entities: Certifications, Role Types, Skills,
        Minor Capabilities, and Industries.
        <br />
        Please resolve any <Tag color="red">Inactive</Tag>tags as quickly as possible, since there are no longer in use.
      </Typography.Paragraph>
      <Toolbar style={{ background: 'transparent', padding: 0 }}>
        <Button icon={expandedRows.length > 0 ? 'minus' : 'plus'} onClick={toggleExpandAll}>
          {expandedRows.length > 0 ? 'Collapse All' : 'Expand All'}
        </Button>
      </Toolbar>
      <Spin spinning={loading}>
        <RecursiveTable
          itemList={projectorSkills}
          childrenName="contains"
          expandedRows={expandedRows}
          handleExpandedRowChange={setExpandedRows}
          showHeader={true}
          columns={columns}
          onChange={onChange}
        />
      </Spin>
    </Stack>
  )
}

export default ProjectorLandingPage
